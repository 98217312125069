import React, { useEffect, useState, useRef } from "react";
import "./projectList.scss";
import { IMapPin } from "../../interfaces/iMapPin";
import ProjectListElement from "./projectListElement/ProjectListElement";
import { connect } from "react-redux";
import { IProperties } from "../../interfaces/iProperties";
import { IGoogleMapReference } from "../../interfaces/google/iGoogleMapReference";
import MainFilter from "../mainFilter/MainFilter";
import { IProjectListProps } from "../../interfaces/iProjectListProps";
import LocalLoader from "../globalLoader/LocalLoader";
import { mapDataSlicer } from "../../store/reducers/mapReducer";
import { TimeoutsHelper } from "../../helpers/timeoutsHelper";
import chevron from "../../assets/chevron.svg";
import T from "i18n-react";
import { isAnyFilterSet } from "../../helpers/filterStatusHelper";
import { getProjectKey } from "../../helpers/projectHelper";

const getPageOfSelectedProject = (
  projects: IMapPin[],
  selectedProjectId: number,
  projectsPerLoad: number
) => {
  const projectIdx = projects.findIndex(
    (project: IMapPin) => project.id === selectedProjectId
  );
  if (projectIdx === -1) return 1;
  return Math.ceil((projectIdx + 1) / projectsPerLoad);
};

const mapStateToProps = (state: IProperties) => {
  let projects = [];
  if (state.mapReducer?.filteredAmbassadorList) {
    projects.push(...state.mapReducer?.filteredAmbassadorList);
  }
  if (state.mapReducer?.filteredFamilyList) {
    projects.push(...state.mapReducer?.filteredFamilyList);
  }
  return {
    projects: projects ?? [],
    selectedPin: state.mapReducer?.selectedPin ?? null,
    listLoadingData: state.mapReducer?.listLoadingData,
    filterIsOpened: state.mapReducer?.showFilterSettings,
    mainFilter: state.mapReducer?.mainFilter,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loadDataToTheList: (value: boolean) =>
      dispatch(mapDataSlicer.actions.loadDataToTheList(value)),
  };
};

const ProjectList = (props: IProjectListProps & IGoogleMapReference) => {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [displayProjects, setDisplayProjects] = useState<IMapPin[]>([]);
  const listRef = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef(0);
  const longTimeoutRef = useRef(0); // Additional timeout in case project list doesn't change and loader is turned on
  const projectsPerLoad = 25;
  const listLoadingData = props.listLoadingData;
  const actualProjectsList = props.projects;
  const previousActualProjectsList = useRef<IMapPin[]>([]);
  const loadDataToTheList = props.loadDataToTheList;
  const selectedPin = props.selectedPin;
  const [showList, setShowList] = useState<boolean>(true);

  // Adjust project list to show currently selected pin
  useEffect(() => {
    if (!selectedPin) return;

    const pageOfSelectedProject = getPageOfSelectedProject(
      props.projects,
      selectedPin.id,
      projectsPerLoad
    );
    setPage(pageOfSelectedProject);
    if (selectedPin.id > 0 && pageOfSelectedProject > 0) {
      const element = document.getElementById("project-" + selectedPin.id);
      element?.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [selectedPin, props.projects]);

  const reloadList = () => {
    if (listRef.current !== null) {
      listRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  // Project list updates
  useEffect(() => {
    if (listLoadingData) {
      clearTimeout(longTimeoutRef.current);
      longTimeoutRef.current = window.setTimeout(() => {
        loadDataToTheList(false);
      }, TimeoutsHelper.PROJECT_LIST_LONG_UPDATE);

      if (
        actualProjectsList.length !==
          previousActualProjectsList.current.length ||
        !actualProjectsList.every(
          (value, index) => value === previousActualProjectsList.current[index]
        )
      ) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = window.setTimeout(() => {
          loadDataToTheList(false);
          clearTimeout(longTimeoutRef.current);
        }, TimeoutsHelper.PROJECT_LIST_SHORT_UPDATE);
      }
      setPage(1);
      if (!selectedPin) {
        reloadList();
      }
    }

    previousActualProjectsList.current = actualProjectsList;
  }, [actualProjectsList, listLoadingData, loadDataToTheList, selectedPin]);

  // Update total pages and list of projects visible on current page
  useEffect(() => {
    setTotalPages(Math.round(actualProjectsList.length / projectsPerLoad));
    setDisplayProjects(
      actualProjectsList?.slice(
        (page - 1) * projectsPerLoad,
        page * projectsPerLoad
      )
    );
  }, [actualProjectsList, page]);

  const showPrevPage = () => {
    if (page > 1) {
      setPage(page - 1);
      reloadList();
    }
  };
  const showNextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
      reloadList();
    }
  };
  let containerClassName = "project-list";
  if (props.filterIsOpened) {
    containerClassName += " opened ";
  }
  if (totalPages > 1) {
    containerClassName += " with-pager ";
  }

  return (
    <div className={containerClassName}>
      <div className="project-list-wrapper">
        <MainFilter map={props.map} />
        <div
          className={
            "list " +
            (props.listLoadingData ? "loading " : "") +
            (showList ? "show " : "")
          }
          id="list"
        >
          <div className="timeLoader">
            <LocalLoader loading={props.listLoadingData} />
          </div>
          <div className="projects-container" ref={listRef}>
            {displayProjects?.length > 0 &&
              displayProjects?.map((selectedProject: IMapPin) => (
                <ProjectListElement
                  map={props.map}
                  project={selectedProject}
                  key={getProjectKey(selectedProject)}
                  isSelected={selectedProject.id === selectedPin?.id}
                />
              ))}
            {displayProjects?.length === 0 && isAnyFilterSet(props.mainFilter) && (
              <div className="no-results-wrapper">
                <div className="no-results">
                  {T.translate("noResultsAfterFilter")}
                </div>
              </div>
            )}
            {displayProjects?.length === 0 &&
              !isAnyFilterSet(props.mainFilter) && (
                <div className="no-results-wrapper">
                  <div className="no-results">
                    {T.translate("noResultsWithoutFilter")}
                  </div>
                </div>
              )}
          </div>
          {totalPages > 1 && (
            <div className="pager">
              <div className="buttons">
                <button
                  onClick={showPrevPage}
                  className={
                    "previous navigation-button " +
                    (page === 1 ? "disabled" : "")
                  }
                >
                  <img alt="previous page" src={chevron} />
                </button>
                <button
                  onClick={showNextPage}
                  className={
                    "next navigation-button " +
                    (page === totalPages ? "disabled" : "")
                  }
                >
                  <img alt="next page" src={chevron} />
                </button>
              </div>
            </div>
          )}
        </div>
        <div
          className={"show-hide-button " + (showList ? "" : "collapsed")}
          onClick={() => setShowList(!showList)}
        >
          <img alt="Toggle list" src={chevron} />
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectList);
