import { Component } from "react";
import { IProperties } from "../../../interfaces/iProperties";
import locationSvg from "../../../assets/location.svg";
import { IPosition } from "../../../interfaces/iPosition";
import "./myLocationControls.scss";
import { mapDataSlicer } from "../../../store/reducers/mapReducer";
import { connect } from "react-redux";
import { mapConfig } from "../../../helpers/googleMap.config";

type ComponentState = {
  currentLocation: IPosition | undefined;
};

const mapStateToProps = (state: IProperties) => {
  return state;
};

type MethodType = {
  toggleMyLocation: Function;
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    toggleMyLocation: (value: boolean) =>
      dispatch(mapDataSlicer.actions.setToggleMyLocation(value)),
  };
};

class MyLocationControls extends Component<MethodType & IProperties> {
  state: ComponentState = {
    currentLocation: mapConfig.defaultCenter,
  };

  getMyLocation = () => {
    this.props.toggleMyLocation(!this.props?.mapReducer?.toggleMyLocation);
  };

  render() {
    return (
      <div className="location-button" onClick={this.getMyLocation}>
        <img alt="My location" src={locationSvg} />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyLocationControls);
