import { IEnvParameters } from "../interfaces/iEnvParameters";
import { EnvParametersList } from "./enums/envParametersList";

const getEnv = (parameter: string) => {
  if (window.globalConfig) {
    return window.globalConfig[parameter] ?? process.env[parameter];
  }
  return process.env[parameter] ?? "";
};

const envParameters: IEnvParameters = {
  REACT_APP_API_URL: getEnv(EnvParametersList.REACT_APP_API_URL),
  REACT_APP_GOOGLE_MAP_KEY: getEnv(EnvParametersList.REACT_APP_GOOGLE_MAP_KEY),
  REACT_APP_DD_CLIENT_TOKEN: getEnv(
    EnvParametersList.REACT_APP_DD_CLIENT_TOKEN
  ),
  REACT_APP_DD_APP_ID: getEnv(EnvParametersList.REACT_APP_DD_APP_ID),
  REACT_APP_GOOGLE_RECAPTCHA_KEY: getEnv(
    EnvParametersList.REACT_APP_GOOGLE_RECAPTCHA_KEY
  ),
  NODE_ENV: getEnv(EnvParametersList.NODE_ENV),
};
export default envParameters;
