import { IProperties } from "../../../interfaces/iProperties";
import { IMapPin } from "../../../interfaces/iMapPin";
import ContactForm from "./contactForm/ContactForm";
import { connect } from "react-redux";
import "./projectDetails.scss";
import solarSvg from "../../../assets/solar.svg";
import batterySvg from "../../../assets/battery.svg";
import solarWhiteSvg from "../../../assets/solar-white.svg";
import batteryWhiteSvg from "../../../assets/battery-white.svg";
import roundClose from "../../../assets/round-close.svg";
import {
  formatNumberToKwh,
  formatNumberToKwp,
} from "../../../helpers/unitFormatter";
import { mapDataSlicer } from "../../../store/reducers/mapReducer";
import { isMobile } from "../../../helpers/deviceHelper";
import { getMonthYearDateFormatted } from "../../../helpers/dateFormatter";
import T from "i18n-react";

type ProjectDetailsProps = {
  projectDetailsProject: IMapPin | null;
  closeProjectDetails: Function;
  setImageUrl: Function;
};

const mapStateToProps = (state: IProperties) => {
  return {
    projectDetailsProject: state.mapReducer?.projectDetailsProject ?? null,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    closeProjectDetails: () =>
      dispatch(mapDataSlicer.actions.setProjectDetailsProject(null)),
    setImageUrl: (value: string | null) =>
      dispatch(mapDataSlicer.actions.setImageUrl(value)),
  };
};

export const ProjectDetails = (props: ProjectDetailsProps) => {
  const project = props.projectDetailsProject;
  if (!project) {
    return <div className={"project-details-wrapper"} />;
  }

  const formattedHeroSince = project.heroSince
    ? " · " +
      T.translate("zolarHeroSince")?.toString() +
      " " +
      getMonthYearDateFormatted(
        project.heroSince || "",
        T.translate("locale")?.toString() || "default"
      )
    : "";

  return (
    <div className={"project-details-wrapper " + (project ? "visible" : "")}>
      <img
        className={"close-button"}
        onClick={() => props.closeProjectDetails()}
        src={roundClose}
        alt={"close"}
      />
      <div className="project-details">
        <div className="details-container">
          {project.systemPicture && (
            <div className={"contact-image-container"}>
              <img
                alt={project.configuratorType}
                className="project-image"
                src={project.systemPicture}
                onClick={() => props.setImageUrl(project.systemPicture)}
              />
            </div>
          )}
          <div className="details">
            <div className="title">
              {project.firstName + " " + project.lastName}
            </div>
            <div className="city-and-since-date">
              {project.city + formattedHeroSince}
            </div>
            {project.systemCapacity || project.storageCapacity ? (
              <div className="specs-container">
                {project.systemCapacity ? (
                  <div className="spec">
                    <img
                      src={isMobile() ? solarWhiteSvg : solarSvg}
                      alt="solar"
                    />
                    <div className="value">
                      {formatNumberToKwp(project.systemCapacity)}
                    </div>
                  </div>
                ) : null}
                {project.storageCapacity ? (
                  <div className="spec">
                    <img
                      src={isMobile() ? batteryWhiteSvg : batterySvg}
                      alt="battery"
                    />
                    <div className="value">
                      {formatNumberToKwh(project.storageCapacity)}
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
        <ContactForm />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetails);
