import "./modal.scss";
import { IProperties } from "../../interfaces/iProperties";
import { connect } from "react-redux";
import { mapDataSlicer } from "../../store/reducers/mapReducer";
import closeSvg from "../../assets/close-black.svg";
import React, { FunctionComponent } from "react";

type ModalProps = {
  isModalOpen: boolean;
  closeModal: Function;
};

const mapStateToProps = (state: IProperties) => {
  return {
    isModalOpen: state.mapReducer?.isModalOpen ?? false,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    closeModal: () => dispatch(mapDataSlicer.actions.closeModal()),
  };
};

const Modal: FunctionComponent<ModalProps> = (props) => {
  return (
    <div
      className={"modal " + (props.isModalOpen ? "visible" : "")}
      onClick={(e) =>
        e.target === e.currentTarget ? props.closeModal() : null
      }
    >
      <div className="modal-content">
        {props.children}
        <div
          className="close-button"
          onClick={() => {
            props.closeModal();
          }}
        >
          <img src={closeSvg} alt="Close" />
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
