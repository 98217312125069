import React from "react";
import "./formError.scss";

export type FormErrorPropsType = {
  message: string;
  visible: boolean;
};

const FormError = (props: FormErrorPropsType) => {
  if (!props.visible) {
    return <div className="form-error" />;
  }
  return <div className="form-error">{props.message}</div>;
};

export default FormError;
