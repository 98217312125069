import { Component } from "react";
import { connect } from "react-redux";
import "./globalLoader.scss";
import ZAnimation from "./zAnimation/ZAnimation";
import { IProperties } from "../../interfaces/iProperties";

const mapStoreToProps = (state: IProperties) => {
  return { loading: state.mapReducer?.loadingState };
};

type Loading = {
  loading?: boolean;
};

class GlobalLoader extends Component<Loading, any> {
  render() {
    if (!this.props.loading) {
      return <></>;
    }
    return (
      <div className={"global-loader"}>
        <div className={"zolar-loader"}>
          <ZAnimation />
        </div>
      </div>
    );
  }
}

export default connect(mapStoreToProps, null)(GlobalLoader);
