import isEmailValidator from "validator/lib/isEmail";
import T from "i18n-react";
import libphonenumber from "google-libphonenumber";

export const isRequired = (value: any) =>
  value || typeof value === "number"
    ? undefined
    : T.translate("errorRequired")?.toString() || "errorRequired";

export const isEmail = (value: any) =>
  value && isEmailValidator(value)
    ? undefined
    : T.translate("errorEmail")?.toString() || "errorEmail";

export const isPhoneNumber = (value: any) => {
  const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
  const failedPhoneValidationMessage =
    T.translate("errorPhone")?.toString() || "errorPhone";

  try {
    return value &&
      value.toString().match(/^[0-9 +\-–()/]*$/g) &&
      phoneUtil.isValidNumberForRegion(phoneUtil.parse(value, "DE"), "DE")
      ? undefined
      : failedPhoneValidationMessage;
  } catch (error) {
    return failedPhoneValidationMessage;
  }
};

export const isAccepted = (value: any) => {
  return value || typeof value === "number"
    ? undefined
    : T.translate("errorAccepted")?.toString() || "errorAccepted";
};

export const isCaptchaChecked = (value: any) => {
  return value || typeof value === "number"
    ? undefined
    : T.translate("errorCaptcha")?.toString() || "errorCaptcha";
};

export const composeValidators = (...validators: Function[]) => (value: any) =>
  validators.reduce((error, validator) => error || validator(value), undefined);
