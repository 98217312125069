import { IMobileProjectListProps } from "../../../../interfaces/iMobileProjectListProps";
import { IGoogleMapReference } from "../../../../interfaces/google/iGoogleMapReference";
import { IProperties } from "../../../../interfaces/iProperties";
import { connect } from "react-redux";
import MobileProject from "../mobileProject/MobileProject";
import { IMapPin } from "../../../../interfaces/iMapPin";
import "./mobileProjects.scss";
import { mapDataSlicer } from "../../../../store/reducers/mapReducer";
import T from "i18n-react";
import { getProjectKey } from "../../../../helpers/projectHelper";
import { useEffect, useState } from "react";
import IntersectionHook from "../../../../helpers/hooks/intersectionHook";

function mapStateToProps(state: IProperties) {
  let projects = [];
  if (state.mapReducer?.filteredAmbassadorList) {
    projects.push(...state.mapReducer?.filteredAmbassadorList);
  }
  if (state.mapReducer?.filteredFamilyList) {
    projects.push(...state.mapReducer?.filteredFamilyList);
  }
  return {
    projects: projects ?? [],
    selectedPin: state.mapReducer?.selectedPin ?? null,
    listLoadingData: state.mapReducer?.listLoadingData,
    filterIsOpened: state.mapReducer?.showFilterSettings,
    showProjectList: state.mapReducer?.showProjectList,
    mainFilter: state.mapReducer?.mainFilter,
  };
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    loadDataToTheList: (value: boolean) =>
      dispatch(mapDataSlicer.actions.loadDataToTheList(value)),
  };
};

const MobileProjects = (
  props: IMobileProjectListProps & IGoogleMapReference
) => {
  const showProjectList = props.showProjectList;
  const projects = props.projects;
  const [visibleProjects, setVisibleProjects] = useState<IMapPin[]>([]);
  const selectedPin = props.selectedPin;
  const [
    requiredPinIndexInProjectList,
    setRequiredPinIndexInProjectList,
  ] = useState(0);
  const [intersectionRef, intersectionEntry] = IntersectionHook({});
  const [
    previousIsIntersectingValue,
    setPreviousIsIntersectingValue,
  ] = useState(false);

  useEffect(() => {
    setRequiredPinIndexInProjectList(
      selectedPin ? projects.indexOf(selectedPin) : 0
    );
  }, [projects, selectedPin]);

  useEffect(() => {
    setVisibleProjects(projects?.slice(0, requiredPinIndexInProjectList + 20));
  }, [requiredPinIndexInProjectList, projects]);

  useEffect(() => {
    if (
      intersectionEntry.isIntersecting &&
      intersectionEntry.isIntersecting !== previousIsIntersectingValue
    ) {
      setRequiredPinIndexInProjectList(requiredPinIndexInProjectList + 20);
    }
    setPreviousIsIntersectingValue(intersectionEntry.isIntersecting);
  }, [
    intersectionEntry,
    requiredPinIndexInProjectList,
    previousIsIntersectingValue,
  ]);

  return (
    <div
      className={showProjectList ? "mobile-projects active" : "mobile-projects"}
    >
      {projects.length > 0 ? (
        <div
          className="projects-swiper-container"
          id={"projectsSwipeContainer"}
        >
          {visibleProjects.slice(0, -1).map((selectedProject: IMapPin) => (
            <MobileProject
              map={props.map}
              project={selectedProject}
              key={getProjectKey(selectedProject)}
              isSelected={selectedProject.id === selectedPin?.id}
              projects={projects}
            />
          ))}
          <div ref={intersectionRef} />
          {visibleProjects.slice(-1).map((selectedProject: IMapPin) => (
            <MobileProject
              map={props.map}
              project={selectedProject}
              key={getProjectKey(selectedProject)}
              isSelected={selectedProject.id === selectedPin?.id}
              projects={projects}
            />
          ))}
        </div>
      ) : (
        <div
          className="projects-swiper-container"
          id={"projectsSwipeContainer"}
        >
          <div className="no-results-wrapper">
            <div className="no-results">
              {T.translate("noResultsAfterFilter")}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileProjects);
