import React, { useEffect, useRef, useState } from "react";
import GoogleMapReact from "google-map-react";
import IGooglePoint from "../../interfaces/google/iGooglePoint";
import { pointType } from "../../helpers/enums/pointType";
import { clusterConfig, mapConfig } from "../../helpers/googleMap.config";
import {
  filterPointsByValues,
  findClosestMarker,
  getMarkers,
  getPoints,
  panMap,
} from "../../helpers/mapHelpers";
import "./map.scss";
import { IPosition } from "../../interfaces/iPosition";
import { IMapPin } from "../../interfaces/iMapPin";
import { mapDataSlicer } from "../../store/reducers/mapReducer";
import { IProperties } from "../../interfaces/iProperties";
import { connect } from "react-redux";
import useSupercluster from "use-supercluster";
import { FilterTypes } from "../../helpers/enums/filterTypes";
import ProjectList from "../projectList/ProjectList";
import SearchInput from "../mainFilter/searchInput/SearchInput";
import ProjectDetails from "../projectList/projectDetails/ProjectDetails";
import ProjectContainer from "../mobile/project/projectContainer/ProjectContainer";
import { isMobile } from "../../helpers/deviceHelper";
import zolarLogoSvg from "../../assets/zolar-logo.svg";
import { IMainFilter } from "../../interfaces/iMainFilter";
import { TimeoutsHelper } from "../../helpers/timeoutsHelper";
import { IExternalConfiguration } from "../../interfaces/iExternalConfiguration";

let currentToggle = false;

/**
 * @var IMapPin[] ambassador
 * @var IMapPin[] family
 * @constructor
 */

type MapProps = {
  selectedPin: IMapPin | null;
  setSelectedPin: Function;
  setCurrentShownProjects: Function;
  loadDataToTheList: Function;

  filteredFamilyList: IMapPin[];
  heroes: IMapPin[];
  family: IMapPin[];
  partners: IMapPin[];
  toggleMyLocation: boolean;
  initZoom?: number;
  initCoordinates?: IPosition;
  clientFilter: string;
  mainFilter: IMainFilter;
  externalConfiguration?: IExternalConfiguration;
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setSelectedPin: (value: IMapPin | null) =>
      dispatch(mapDataSlicer.actions.setSelectedPin(value)),
    setCurrentShownProjects: (value: { f: IMapPin[]; h: IMapPin[] }) =>
      dispatch(mapDataSlicer.actions.setCurrentShownProjects(value)),
    loadDataToTheList: (value: boolean) =>
      dispatch(mapDataSlicer.actions.loadDataToTheList(value)),
  };
};

function mapStateToProps(state: IProperties) {
  return {
    selectedPin: state.mapReducer?.selectedPin ?? null,
    filteredFamilyList: state.mapReducer?.filteredFamilyList ?? [],
    filteredAmbassadorList: state.mapReducer?.filteredAmbassadorList ?? [],
    heroes: state.mapReducer?.ambassador ?? [],
    family: state.mapReducer?.family ?? [],
    partners: state.mapReducer?.partner ?? [],
    toggleMyLocation: state.mapReducer?.toggleMyLocation ?? false,
    initZoom: state.mapReducer?.externalConfiguration?.zoom,
    initCoordinates: state.mapReducer?.externalConfiguration?.location,
    clientFilter: state.mapReducer?.clientFilter ?? FilterTypes.FAMILY,
    mainFilter: state.mapReducer.mainFilter,
    externalConfiguration: state.mapReducer?.externalConfiguration,
  };
}

const getCurrentZoomPoints = (clusters: any, superCluster: any): IMapPin[] => {
  let points: IMapPin[] = [];
  clusters.forEach((cluster: any) => {
    if (cluster.properties.cluster_id) {
      points = points.concat(
        superCluster
          .getLeaves(cluster.properties.cluster_id, "Infinity")
          .map((el: IGooglePoint) => el.properties.point)
      );
    } else {
      points.push(cluster.properties.point);
    }
  });
  return points;
};

const ZolarLogo = () => (
  <img
    src={zolarLogoSvg}
    alt="zolar"
    className="fixed bottom-5 right-0 w-32 pb-8 pr-4"
  />
);

// safari issue: https://github.com/google-map-react/google-map-react/issues/866
// copy of default bounds calculated in Chrome in order to see initial clusters
export const defaultBounds = [
  -6.46214692812498, 44.13585793466396, 20.98170072812502, 57.11309958164617,
];

const MapWrapper = (props: MapProps) => {
  // setup map
  const mapRef: any = useRef(null);
  const [bounds, setBounds] = useState<number[] | null>(defaultBounds);
  const [zoom, setZoom] = useState<number>(7);
  const [mapChangeToggle, setMapChangeToggle] = useState<boolean>(false);

  let friendPins: IMapPin[] = [];
  if (
    !props.externalConfiguration?.hideFilter &&
    !props.externalConfiguration?.hideProjectPins &&
    props.clientFilter === FilterTypes.FAMILY
  ) {
    friendPins = filterPointsByValues(props.family, props.mainFilter);
  }
  if (
    props.externalConfiguration?.hideFilter &&
    !props.externalConfiguration?.hideProjectPins
  ) {
    friendPins = props.family;
  }
  let fPoints: IGooglePoint[] = getPoints(friendPins, pointType.FRIEND);

  let heroPins: IMapPin[] = [];
  if (
    !props.externalConfiguration?.hideFilter &&
    !props.externalConfiguration?.hideHeroesPins
  ) {
    heroPins = filterPointsByValues(props.heroes, props.mainFilter);
  }
  if (
    props.externalConfiguration?.hideFilter &&
    !props.externalConfiguration?.hideHeroesPins
  ) {
    heroPins = props.heroes;
  }
  let hPoints: IGooglePoint[] = getPoints(heroPins, pointType.HERO);

  let partnerPins: IMapPin[] = [];
  if (
    !props.externalConfiguration?.hidePartnerPins &&
    props.clientFilter === FilterTypes.FAMILY
  ) {
    partnerPins = props.partners;
  }
  let pPoints: IGooglePoint[] = getPoints(partnerPins, pointType.PARTNER);
  let currentState = props.toggleMyLocation;
  if (currentState !== currentToggle) {
    if (mapRef.current) {
      navigator?.geolocation.getCurrentPosition(
        ({ coords: { latitude: lat, longitude: lng } }) => {
          let myLocation: IPosition = { lat: lat, lng: lng };
          let closestMarker = findClosestMarker(props.heroes, myLocation);
          mapRef.current.fitBounds(closestMarker, {
            width: mapRef.current.getDiv().offsetWidth,
            height: mapRef.current.getDiv().offsetHeight,
          });
        }
      );
      currentToggle = currentState;
    }
  }

  // get clusters
  const { clusters: heroClusters, supercluster: hSupercluster } =
    useSupercluster({
      points: hPoints,
      bounds,
      zoom,
      options: clusterConfig,
    });
  const { clusters: friendClusters, supercluster: fSupercluster } =
    useSupercluster({
      points: fPoints,
      bounds,
      zoom,
      options: clusterConfig,
    });
  const { clusters: partnerClusters, supercluster: pSupercluster } =
    useSupercluster({
      points: pPoints,
      bounds,
      zoom,
      options: clusterConfig,
    });

  let shownTimer = useRef(0);

  const loadDataToTheList = props.loadDataToTheList;
  const setCurrentShownProjects = props.setCurrentShownProjects;

  useEffect(() => {
    if (!mapRef.current) return;

    setBounds([
      mapRef.current.getBounds().getSouthWest().lng(),
      mapRef.current.getBounds().getSouthWest().lat(),
      mapRef.current.getBounds().getNorthEast().lng(),
      mapRef.current.getBounds().getNorthEast().lat(),
    ]);

    // safari issue: https://github.com/google-map-react/google-map-react/issues/866
    // fake resize event to rerender clusters when the bounds change (eg. zoom)
    window.dispatchEvent(new Event("resize"));
  }, [mapChangeToggle]);

  useEffect(() => {
    loadDataToTheList(true);
    clearTimeout(shownTimer.current);
    shownTimer.current = window.setTimeout(() => {
      setCurrentShownProjects({
        h: getCurrentZoomPoints(heroClusters, hSupercluster),
        f: getCurrentZoomPoints(friendClusters, fSupercluster),
      });
    }, TimeoutsHelper.MAP_TRIGGER_TOGGLE_TIME);
  }, [
    mapChangeToggle,
    hSupercluster,
    heroClusters,
    fSupercluster,
    friendClusters,
    pSupercluster,
    partnerClusters,
    loadDataToTheList,
    setCurrentShownProjects,
  ]);

  if (props.initZoom) {
    mapConfig.defaultZoom = props.initZoom;
  }
  if (props.externalConfiguration?.hideFilter) {
    mapConfig.defaultCenter = { lat: 51.0767466, lng: 10.4597769 };
  }
  if (props.initCoordinates) {
    mapConfig.defaultCenter = props.initCoordinates;
  }

  const openCluster = (superCluster: any, clusterId: number) => {
    props.loadDataToTheList(true);
    const bounds = new window.google.maps.LatLngBounds();
    superCluster
      .getLeaves(clusterId, "Infinity")
      .forEach((el: IGooglePoint) => {
        bounds.extend(
          new window.google.maps.LatLng(
            el.geometry.coordinates[1],
            el.geometry.coordinates[0]
          )
        );
      });
    mapRef.current.fitBounds(bounds);
    panMap(mapRef);
  };

  return (
    <div className={`map-wrapper`}>
      <div className="side-menu-wrapper">
        <SearchInput
          map={mapRef}
          points={props.heroes.concat(props.family)}
          hideFilter={props.externalConfiguration?.hideFilter}
          hideSearch={props.externalConfiguration?.hideSearch}
        />
        {!isMobile() && !props.externalConfiguration?.hideFilter ? (
          <div className={"project-list-main-container"}>
            <div className={"project-list"}>
              <ProjectList map={mapRef} />
            </div>
            <ProjectDetails />
          </div>
        ) : null}
      </div>
      {isMobile() && !props.externalConfiguration?.hideFilter ? (
        <div className="mobile-wrapper">
          <ProjectContainer map={mapRef} />
          <ProjectDetails />
        </div>
      ) : null}
      {/* @ts-ignore */}
      <GoogleMapReact
        {...mapConfig}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => {
          mapRef.current = map;
        }}
        onZoomAnimationEnd={() => {
          setMapChangeToggle(!mapChangeToggle);
        }}
        onDragEnd={() => {
          setMapChangeToggle(!mapChangeToggle);
        }}
        onChildClick={() => {
          setMapChangeToggle(!mapChangeToggle);
        }}
        onChange={({ zoom }) => {
          if (zoom) setZoom(zoom);
          setMapChangeToggle(!mapChangeToggle);
        }}
      >
        {getMarkers(
          friendClusters,
          zoom,
          pointType.FRIEND,
          props.selectedPin,
          props.setSelectedPin,
          openCluster,
          mapRef,
          fSupercluster
        )}
        {getMarkers(
          partnerClusters,
          zoom,
          pointType.PARTNER,
          props.selectedPin,
          props.setSelectedPin,
          openCluster,
          mapRef,
          pSupercluster
        )}
        {getMarkers(
          heroClusters,
          zoom,
          pointType.HERO,
          props.selectedPin,
          props.setSelectedPin,
          openCluster,
          mapRef,
          hSupercluster
        )}
      </GoogleMapReact>
      <ZolarLogo />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MapWrapper);
