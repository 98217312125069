import { Component, createRef } from "react";
import "./searchInput.scss";
import MyLocationControls from "../myLocationControls/MyLocationControls";
import MobileFilter from "../../mobile/filter/MobileFilter";
import { TimeoutsHelper } from "../../../helpers/timeoutsHelper";
import { findClosestMarker, panMap } from "../../../helpers/mapHelpers";
import { ISearchInput } from "../../../interfaces/iSearchInput";
import { IPosition } from "../../../interfaces/iPosition";
import searchSvg from "../../../assets/search.svg";

class SearchInput extends Component<ISearchInput> {
  onPlacesChanged = () => {
    let places: any = this.searchBox.getPlaces();
    let bounds = new window.google.maps.LatLngBounds();

    places?.forEach((place: any) => {
      if (place.geometry.viewport) {
        bounds.union(place.geometry.viewport);
      }
      let location: IPosition = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      // @ts-ignore
      bounds = findClosestMarker(this.props.points, location);
    });

    this.props.map.current.fitBounds(bounds);
    panMap(this.props.map);
  };

  inputRef: any;
  searchBox: any;
  timeout: any;

  initSearchBox = () => {
    if (this.props.map && window.google && !this.props.hideSearch) {
      this.searchBox = new window.google.maps.places.SearchBox(
        this.inputRef.current
      );
      this.searchBox.addListener("places_changed", this.onPlacesChanged);
    }
  };

  constructor(props: any) {
    super(props);
    this.inputRef = createRef();
  }

  reset = () => {
    this.inputRef.current.value = null;
  };

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  render() {
    if (this.searchBox === undefined) {
      this.timeout = setTimeout(() => {
        this.initSearchBox();
      }, TimeoutsHelper.SEARCH_INPUT_TIMER);
    }
    let classNames = "search-input-wrapper";
    if (this.props.hideFilter) {
      classNames += " hide-filter ";
    }
    if (this.props.hideSearch) {
      classNames += " hide-search ";
    }
    return (
      <div className={classNames}>
        {!this.props.hideSearch && (
          <div className="search-input">
            <input
              id="mapSearchInput"
              ref={this.inputRef}
              placeholder={"Ort suchen"}
              type="text"
            />
            <div className="search-input-button">
              <img alt="Search" src={searchSvg} />
            </div>
          </div>
        )}
        {!this.props.hideSearch && <MyLocationControls />}
        {!this.props.hideFilter && <MobileFilter map={this.props.map} />}
      </div>
    );
  }
}

export default SearchInput;
