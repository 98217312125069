export const formatNumberToKwp = (value: number) => {
  if (value !== 0 && !value) {
    return null;
  }
  return Number(value).toFixed(1).toString().replace(".", ",") + " kWp";
};

export const formatNumberToKwh = (value: number) => {
  if (value !== 0 && !value) {
    return null;
  }
  return Number(value).toFixed(1).toString().replace(".", ",") + " kWh";
};
