import { IPosition } from "../interfaces/iPosition";

export const isMobile = () => {
  return window.innerWidth < 769;
};

export const getInitZoom = (): number => {
  if (window.innerWidth < 1350 || window.innerHeight < 950) {
    return 6;
  }
  return 7;
};

export const getInitCoordinates = (): IPosition => {
  if (isMobile()) {
    return { lat: 51.0767466, lng: 10.4597769 };
  }
  return { lat: 51.0767466, lng: 7.2597769 };
};
