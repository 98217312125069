import { IState } from "../../interfaces/iState";
import { FilterTypes } from "../../helpers/enums/filterTypes";
import { initFilterState } from "./initFilterState";

export const initialState: IState = {
  family: [],
  ambassador: [],
  partner: [],
  filteredAmbassadorList: [],
  filteredFamilyList: [],
  externalConfiguration: {},
  lightboxImageUrl: null,
  isModalOpen: false,
  loadingState: true,
  selectedPin: null,
  toggleMyLocation: false,
  currentShownFriends: [],
  currentShownHeroes: [],
  projectDetailsProject: null,
  clientFilter: FilterTypes.FAMILY,
  formSent: null,
  showFilterSettings: false,
  mainFilter: initFilterState,
  listLoadingData: false,
  showProjectList: false,
};
