import "./lightbox.scss";
import { IProperties } from "../../../interfaces/iProperties";
import { connect } from "react-redux";

type LightboxProps = {
  lightboxImageUrl?: string;
};

function mapStateToProps(state: IProperties) {
  return {
    lightboxImageUrl: state.mapReducer?.lightboxImageUrl ?? "",
  };
}

const Lightbox = (props: LightboxProps) => {
  if (!props.lightboxImageUrl) {
    return null;
  }
  return (
    <div className="image-container">
      <img
        src={props.lightboxImageUrl ? props.lightboxImageUrl : ""}
        alt="Project"
      />
    </div>
  );
};

export default connect(mapStateToProps)(Lightbox);
