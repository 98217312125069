import { IMapPin } from "../../../../interfaces/iMapPin";
import { IGoogleMapReference } from "../../../../interfaces/google/iGoogleMapReference";
import React, { useEffect, useRef } from "react";
import { pointType } from "../../../../helpers/enums/pointType";
import noImagePv from "../../../../assets/pv.svg";
import noImagePvS from "../../../../assets/pv-speicher.svg";
import noImageSp from "../../../../assets/speicher.svg";
import noImageSo from "../../../../assets/sonstiges.svg";
import { mapDataSlicer } from "../../../../store/reducers/mapReducer";
import { connect } from "react-redux";
import "./mobileProject.scss";
import T from "i18n-react";
import solarSvg from "../../../../assets/solar.svg";
import {
  formatNumberToKwh,
  formatNumberToKwp,
} from "../../../../helpers/unitFormatter";
import batterySvg from "../../../../assets/battery.svg";

type MobileProjectProperties = {
  project: IMapPin;
  isSelected: boolean;
  setImageUrl: Function;
  setProjectDetailsProject: Function;
  setSelectedPin: Function;
  projects?: IMapPin[];
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setImageUrl: (value: string | null) =>
      dispatch(mapDataSlicer.actions.setImageUrl(value)),
    setProjectDetailsProject: (value: IMapPin | null) =>
      dispatch(mapDataSlicer.actions.setProjectDetailsProject(value)),
    setSelectedPin: (value: IMapPin | null) =>
      dispatch(mapDataSlicer.actions.setSelectedPin(value)),
  };
};

const MobileProject = (
  props: MobileProjectProperties & IGoogleMapReference
) => {
  const project = props.project;
  const isHero = project.type === pointType.HERO;
  const className = `mobile-project ${isHero ? "hero" : "family"} ${
    props.isSelected ? "selected" : ""
  }`;
  const wasPinAlreadyScrolled = useRef<boolean>(false); // This ref is created to prevent multiple "scroll-to-selected" animations on every project list updates

  const selectMapPin = () => {
    const bounds = new window.google.maps.LatLngBounds();
    bounds.extend(
      new window.google.maps.LatLng(
        props.project?.position.lat,
        props.project?.position.lng
      )
    );
    props.map.current.fitBounds(bounds);
    props.map.current.panBy(0, 130);
    props.setSelectedPin(props.project);
  };

  let backgroundImage = `url(${project.systemPicture})`;
  let noImage = null;
  if (!project.systemPicture) {
    switch (project.configuratorType) {
      case "Neuinstallation PV":
        noImage = noImagePv;
        break;
      case "Neuinstallation PV+Speicher":
        noImage = noImagePvS;
        break;
      case "Nachrüstung Speicher":
        noImage = noImageSp;
        break;
      case "Sonstiges":
        noImage = noImageSo;
        break;
    }
  }

  const projectId = "mob-project-" + props.project.id;
  useEffect(() => {
    if (!props.isSelected) {
      wasPinAlreadyScrolled.current = false;
    }

    if (props.isSelected) {
      const element = document.getElementById(projectId);
      if (element && element.scrollIntoView) {
        element.scrollIntoView({
          behavior: wasPinAlreadyScrolled.current ? "auto" : "smooth",
          inline: "center",
        });
      }
      wasPinAlreadyScrolled.current = true;
    }
  }, [projectId, props.isSelected, props.project, props.projects]);

  return (
    <div className={className} onClick={selectMapPin} id={projectId}>
      <div
        className="project-image"
        style={{
          backgroundImage: backgroundImage,
        }}
      >
        {noImage && <img src={noImage} alt={"empty"} />}
      </div>
      <div className="content">
        <div className={"title" + (isHero ? " hero" : "")}>
          {isHero
            ? project.firstName + " " + project.lastName
            : T.translate(project.configuratorType)}
        </div>
        <div className="city">{project.city}</div>
        <div className="specs">
          {project.systemCapacity ? (
            <div className="specs-element">
              <img className="icon" src={solarSvg} alt="Solar icon" />
              <div className="value">
                {formatNumberToKwp(project.systemCapacity)}
              </div>
            </div>
          ) : null}
          {project.storageCapacity ? (
            <div className="specs-element">
              <img className="icon" src={batterySvg} alt="Battery icon" />
              <div className="value">
                {formatNumberToKwh(project.storageCapacity)}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {isHero && (
        <div
          className={"button-primary button-black button-small"}
          onClick={() => props.setProjectDetailsProject(props.project)}
        >
          {T.translate("requestRecall")}
        </div>
      )}
    </div>
  );
};

export default connect(null, mapDispatchToProps)(MobileProject);
