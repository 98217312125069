import "./mainFilter.scss";
import { IGoogleMapReference } from "../../interfaces/google/iGoogleMapReference";
import Slider from "@material-ui/core/Slider";
import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { IProperties } from "../../interfaces/iProperties";
import { IMapPin } from "../../interfaces/iMapPin";
import { mapDataSlicer } from "../../store/reducers/mapReducer";
import ClientTypeFilter from "../clientTypeFilter/ClientTypeFilter";
import solarSvg from "../../assets/solar.svg";
import batterySvg from "../../assets/battery.svg";
import filterSvg from "../../assets/sliders-h.svg";
import closeSvg from "../../assets/close-black.svg";
import T from "i18n-react";
import { isMobile } from "../../helpers/deviceHelper";
import { filterPointsByValues } from "../../helpers/mapHelpers";
import { TimeoutsHelper } from "../../helpers/timeoutsHelper";
import {
  formatNumberToKwh,
  formatNumberToKwp,
} from "../../helpers/unitFormatter";

const mapStoreToProps = (state: IProperties) => state;

const mapDispatchToProps = (dispatch: any) => {
  return {
    filterAmbassadors: (list: IMapPin[]) =>
      dispatch(mapDataSlicer.actions.filterAmbassadors(list)),
    filterFamily: (list: IMapPin[]) =>
      dispatch(mapDataSlicer.actions.filterFamily(list)),
    toggleFilterSettings: (toggle: boolean) =>
      dispatch(mapDataSlicer.actions.toggleFilterSettings(toggle)),
    updateInitState: (values: [number, number, number, number]) =>
      dispatch(mapDataSlicer.actions.updateInitState(values)),
    setCapacity: (values: [number, number]) =>
      dispatch(mapDataSlicer.actions.setCapacity(values)),
    setStorage: (values: [number, number]) =>
      dispatch(mapDataSlicer.actions.setStorage(values)),
    loadDataToTheList: (load: boolean) =>
      dispatch(mapDataSlicer.actions.loadDataToTheList(load)),
  };
};

type FilterActions = {
  filterAmbassadors: Function;
  filterFamily: Function;
  toggleFilterSettings: Function;
  setCapacity: Function;
  updateInitState: Function;
  setStorage: Function;
  loadDataToTheList: Function;
};

const MainFilter = (
  props: IProperties & IGoogleMapReference & FilterActions
) => {
  const getCapacityNumbers = () => {
    if (!props.mapReducer?.ambassador?.length) {
      return [0];
    }
    return [
      ...props.mapReducer?.ambassador.map((a) => a.systemCapacity),
      ...props.mapReducer?.family.map((a) => a.systemCapacity),
    ];
  };
  const getStorageNumbers = () => {
    if (!props.mapReducer?.ambassador?.length) {
      return [0];
    }
    return [
      ...props.mapReducer?.ambassador.map((a) => a.storageCapacity),
      ...props.mapReducer?.family.map((a) => a.storageCapacity),
    ];
  };
  const getMinCapacity = () => {
    return (
      props.mapReducer?.externalConfiguration?.capacityMin ??
      Math.min(...getCapacityNumbers())
    );
  };
  const getMaxCapacity = () => {
    return (
      props.mapReducer?.externalConfiguration?.capacityMax ??
      Math.max(...getCapacityNumbers())
    );
  };
  const getMinStorage = () => {
    return (
      props.mapReducer?.externalConfiguration?.storageMin ??
      Math.min(...getStorageNumbers())
    );
  };
  const getMaxStorage = () => {
    return (
      props.mapReducer?.externalConfiguration?.storageMax ??
      Math.max(...getStorageNumbers())
    );
  };

  let filterTimeoutRef: any = useRef(null);

  const updateFilterValues = () => {
    let minCapacity = getMinCapacity();
    let maxCapacity = getMaxCapacity();
    let minStorage = getMinStorage();
    let maxStorage = getMaxStorage();

    props.updateInitState([minCapacity, maxCapacity, minStorage, maxStorage]);
  };

  const filterList = (list: IMapPin[]) => {
    return filterPointsByValues(list, props.mapReducer.mainFilter);
  };

  const filterProjects = () => {
    if (!props.mapReducer?.currentShownHeroes) {
      return;
    }
    props.loadDataToTheList(true);
    props.filterAmbassadors(filterList(props.mapReducer?.currentShownHeroes));
    props.filterFamily(filterList(props.mapReducer?.currentShownFriends));
  };

  const filterByCapacity = (event: any, newValue: number | number[]) => {
    clearTimeout(filterTimeoutRef.current);
    props.setCapacity(newValue);
    if (!isMobile()) {
      doFilter();
    }
  };
  const filterByStorage = (event: any, newValue: number | number[]) => {
    clearTimeout(filterTimeoutRef.current);
    props.setStorage(newValue);
    if (!isMobile()) {
      doFilter();
    }
  };

  const doFilter = () => {
    filterTimeoutRef.current = setTimeout(() => {
      filterProjects();
    }, TimeoutsHelper.FILTER_PROJECTS_LIST);
  };

  let prevAmbassadorListRef = useRef(props.mapReducer?.ambassador);
  useEffect(() => {
    if (
      prevAmbassadorListRef.current?.length !==
      props.mapReducer?.ambassador.length
    ) {
      updateFilterValues();
      prevAmbassadorListRef.current = props.mapReducer?.ambassador;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.mapReducer?.ambassador]);

  let prevCurrentShownHeroesListRef = useRef(
    props.mapReducer?.currentShownHeroes
  );
  useEffect(() => {
    if (
      prevCurrentShownHeroesListRef.current?.length !==
      props.mapReducer?.currentShownHeroes.length
    ) {
      prevCurrentShownHeroesListRef.current =
        props.mapReducer?.currentShownHeroes;
      clearTimeout(filterTimeoutRef);
      doFilter();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.mapReducer?.currentShownHeroes]);

  let prevCurrentShownFriendsListRef = useRef(
    props.mapReducer?.currentShownFriends
  );
  useEffect(() => {
    if (
      prevCurrentShownFriendsListRef.current?.length !==
      props.mapReducer?.currentShownFriends.length
    ) {
      prevCurrentShownFriendsListRef.current =
        props.mapReducer?.currentShownFriends;
      clearTimeout(filterTimeoutRef);
      doFilter();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.mapReducer?.currentShownFriends]);

  const resetFilter = () => {
    updateFilterValues();
    doFilter();
  };
  return (
    <div className="main-filter">
      <div className="top-container">
        <ClientTypeFilter />
        <div
          className="filter-toggle-wrapper"
          onClick={() =>
            props.toggleFilterSettings(!props.mapReducer?.showFilterSettings)
          }
        >
          {props.mapReducer?.showFilterSettings ? (
            <div className="filter-toggle">
              {T.translate("closeFilters")}
              <img src={closeSvg} alt="Close filter" />
            </div>
          ) : (
            <div className="filter-toggle">
              {T.translate("openFilters")}
              <img src={filterSvg} alt="Open filter" />
            </div>
          )}
        </div>
      </div>
      <div
        className={
          "slider-filters" +
          (props.mapReducer?.showFilterSettings ? " show" : "")
        }
      >
        <div className="filter capacity-filter">
          <div className="filter-title">
            <img src={solarSvg} alt="solar" />
            {T.translate("capacity")}
          </div>
          {props.mapReducer?.mainFilter.capacity.initMin !== undefined && (
            <Slider
              step={0.5}
              min={props.mapReducer?.mainFilter.capacity.initMin}
              max={props.mapReducer?.mainFilter.capacity.initMax}
              value={[
                props.mapReducer?.mainFilter.capacity.min,
                props.mapReducer?.mainFilter.capacity.max,
              ]}
              valueLabelDisplay="off"
              aria-labelledby="range-slider"
              onChange={filterByCapacity}
            />
          )}
          <div className="filter-value left">
            {formatNumberToKwp(props.mapReducer?.mainFilter.capacity.min)}
          </div>
          <div className="filter-value right">
            {formatNumberToKwp(props.mapReducer?.mainFilter.capacity.max)}
          </div>
        </div>
        <div className="filter storage-filter">
          <div className="filter-title">
            <img src={batterySvg} alt="battery" />
            {T.translate("storage")}
          </div>
          {props.mapReducer?.mainFilter.storage.initMin !== undefined && (
            <Slider
              min={props.mapReducer?.mainFilter.storage.initMin}
              step={0.5}
              max={props.mapReducer?.mainFilter.storage.initMax}
              value={[
                props.mapReducer?.mainFilter.storage.min,
                props.mapReducer?.mainFilter.storage.max,
              ]}
              valueLabelDisplay="off"
              aria-labelledby="range-slider"
              onChange={filterByStorage}
            />
          )}
          <div className="filter-value left">
            {formatNumberToKwh(props.mapReducer?.mainFilter.storage.min)}
          </div>
          <div className="filter-value right">
            {formatNumberToKwh(props.mapReducer?.mainFilter.storage.max)}
          </div>
        </div>
        <div className="do-mobile-filter-container">
          <button
            className={"button-secondary button-small"}
            onClick={resetFilter}
          >
            {T.translate("doFilterButton")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStoreToProps, mapDispatchToProps)(MainFilter);
