import { IExternalConfiguration } from "../interfaces/iExternalConfiguration";

let params: IExternalConfiguration = {};
export function parse(parameters: URLSearchParams) {
  setFloatValue(params, "capacityMin", parameters.get("capacityMin"));
  setFloatValue(params, "capacityMax", parameters.get("capacityMax"));
  setFloatValue(params, "storageMin", parameters.get("storageMin"));
  setFloatValue(params, "storageMax", parameters.get("storageMax"));
  setFloatValue(params, "zoom", parameters.get("zoom"));
  setBooleanParameter(params, "hideFilter", parameters.get("hideFilter"));
  setBooleanParameter(params, "hideSearch", parameters.get("hideSearch"));
  setBooleanParameter(params, "hideLegend", parameters.get("hideLegend"));
  setBooleanParameter(
    params,
    "hideIntroModal",
    parameters.get("hideIntroModal")
  );
  setBooleanParameter(
    params,
    "hidePartnerPins",
    parameters.get("hidePartnerPins")
  );
  setBooleanParameter(
    params,
    "hideProjectPins",
    parameters.get("hideProjectPins")
  );
  setBooleanParameter(
    params,
    "hideHeroesPins",
    parameters.get("hideHeroesPins")
  );
  let locationLat = parameters.get("locationLat");
  let locationLng = parameters.get("locationLng");
  if (locationLat !== null && locationLng !== null) {
    params.location = {
      lat: Number.parseFloat(locationLat),
      lng: Number.parseFloat(locationLng),
    };
  }

  return params;
}

function setBooleanParameter(
  object: any,
  parameterName: any,
  parameterValue: any
) {
  if (parameterValue) {
    object[parameterName] = parameterValue === "1";
  }
}
function setFloatValue(object: any, parameterName: any, parameterValue: any) {
  if (parameterValue) {
    object[parameterName] = Number.parseFloat(parameterValue);
  }
}
