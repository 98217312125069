import { Component } from "react";

class ZAnimation extends Component<any, any> {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="ZAnimation"
        viewBox="0 0 328 328"
      >
        <rect
          id="backgroundrect"
          width="100%"
          height="100%"
          x="0"
          y="0"
          fill="white"
          stroke="none"
          rx="100%"
        />

        <g className="currentLayer" style={{ transformOrigin: "center" }}>
          <title>Layer 1</title>
          <path
            fill="#bedce1"
            className="cls-1"
            d="M101.27643,240.04368999999997 l3.74655,-8.7953 A109.78673,109.78673 0 0 1 206.02775999999994,164.48700000000002 H326.77200000000005 a162.82439,162.82439 0 0 0 -307.0592,-75.55657 h206.8966 l-3.74655,8.79531 A109.78679,109.78679 0 0 1 121.85804999999999,164.48700000000002 H1.113839999999982 A162.82443,162.82443 0 0 0 308.173,240.04368999999997 z"
            id="svg_6"
          />
          <animateTransform
            attributeType="XML"
            attributeName="transform"
            type="scale"
            values="0.8;1;0.8"
            begin="0s"
            dur="2s"
            repeatCount="indefinite"
          />
        </g>
      </svg>
    );
  }
}

export default ZAnimation;
