import React from "react";
import "./projectListElement.scss";
import { IMapPin } from "../../../interfaces/iMapPin";
import solarSvg from "../../../assets/solar.svg";
import batterySvg from "../../../assets/battery.svg";
import noImagePv from "../../../assets/pv.svg";
import noImagePvS from "../../../assets/pv-speicher.svg";
import noImageSo from "../../../assets/sonstiges.svg";
import noImageSp from "../../../assets/speicher.svg";
import { mapDataSlicer } from "../../../store/reducers/mapReducer";
import { connect } from "react-redux";
import {
  formatNumberToKwh,
  formatNumberToKwp,
} from "../../../helpers/unitFormatter";
import { pointType } from "../../../helpers/enums/pointType";
import { IGoogleMapReference } from "../../../interfaces/google/iGoogleMapReference";
import T from "i18n-react";

type ProjectListElementProps = {
  project: IMapPin | null;
  setImageUrl: Function;
  setProjectDetailsProject: Function;
  setSelectedPin: Function;
  isSelected: boolean;
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setImageUrl: (value: string | null) =>
      dispatch(mapDataSlicer.actions.setImageUrl(value)),
    setProjectDetailsProject: (value: IMapPin | null) =>
      dispatch(mapDataSlicer.actions.setProjectDetailsProject(value)),
    setSelectedPin: (value: IMapPin | null) =>
      dispatch(mapDataSlicer.actions.setSelectedPin(value)),
  };
};

export const ProjectListElement = (
  props: ProjectListElementProps & IGoogleMapReference
) => {
  if (!props.project) {
    return <div />;
  }

  const selectMapPin = () => {
    if (props.isSelected) return;
    const bounds = new window.google.maps.LatLngBounds();
    bounds.extend(
      new window.google.maps.LatLng(
        props.project?.position.lat,
        props.project?.position.lng
      )
    );
    props.map.current.fitBounds(bounds);
    props.map.current.panBy(-225, 0);
    props.setSelectedPin(props.project);
  };

  const project = props.project;
  const isHero = project.type === pointType.HERO;

  let backgroundImage = `url(${project.systemPicture})`;
  let noImage = null;
  if (!project.systemPicture) {
    switch (project.configuratorType) {
      case "Neuinstallation PV":
        noImage = noImagePv;
        break;
      case "Neuinstallation PV+Speicher":
        noImage = noImagePvS;
        break;
      case "Nachrüstung Speicher":
        noImage = noImageSp;
        break;
      case "Sonstiges":
        noImage = noImageSo;
        break;
    }
  }

  return (
    <div
      className={"project-list-element " + (props.isSelected ? "selected" : "")}
      id={"project-" + props.project.id}
      onClick={selectMapPin}
    >
      <div
        className="project-image"
        style={{
          backgroundImage: backgroundImage,
        }}
      >
        {noImage && <img src={noImage} alt={"empty"} />}
      </div>
      <div className="content">
        <div className="title">
          {isHero
            ? project.firstName + " " + project.lastName
            : T.translate(project.configuratorType)}
        </div>
        <div className="city">{project.city}</div>
        <div className="bottom-container">
          <div className="specs">
            {project.systemCapacity ? (
              <div className="specs-element">
                <img className="icon" src={solarSvg} alt="Solar icon" />
                <div className="value">
                  {formatNumberToKwp(project.systemCapacity)}
                </div>
              </div>
            ) : null}
            {project.storageCapacity ? (
              <div className="specs-element">
                <img className="icon" src={batterySvg} alt="Battery icon" />
                <div className="value">
                  {formatNumberToKwh(project.storageCapacity)}
                </div>
              </div>
            ) : null}
          </div>
          {project.type === pointType.HERO ? (
            <button
              className="button-primary button-black button-small"
              onClick={() => props.setProjectDetailsProject(project)}
            >
              {T.translate("requestRecall")}
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default connect(null, mapDispatchToProps)(ProjectListElement);
