import { initialState } from "../initStates/initMainState";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getMapData } from "../actions/mapActions";
import { IPosition } from "../../interfaces/iPosition";
import { IExternalConfiguration } from "../../interfaces/iExternalConfiguration";
import { IMapPin } from "../../interfaces/iMapPin";
import { FilterTypes } from "../../helpers/enums/filterTypes";

export const mapDataSlicer = createSlice({
  name: "mapData",
  initialState,
  reducers: {
    setExternal(state, action: PayloadAction<IExternalConfiguration>) {
      state.externalConfiguration = action.payload;
    },
    setImageUrl(state, action: PayloadAction<string | null>) {
      state.lightboxImageUrl = action.payload;
      state.isModalOpen = !!action.payload;
    },
    openModal(state) {
      state.isModalOpen = true;
    },
    closeModal(state) {
      state.isModalOpen = false;
      state.lightboxImageUrl = null;
      state.isPopupOpen = false;
    },
    openPopup(state) {
      state.isPopupOpen = true;
      state.isModalOpen = true;
    },
    filterAmbassadors(state, action: PayloadAction<IMapPin[]>) {
      state.filteredAmbassadorList = action.payload;
    },
    filterFamily(state, action: PayloadAction<IMapPin[]>) {
      state.filteredFamilyList = action.payload;
    },
    setClientFilter(state, action: PayloadAction<string>) {
      state.clientFilter = action.payload;
    },
    setSelectedPin(state, action: PayloadAction<IMapPin | null>) {
      state.listLoadingData = true;
      state.selectedPin = action.payload;
      if (action.payload) state.showProjectList = true;
    },
    setLocation(state, action: PayloadAction<IPosition>) {
      state.location = action.payload;
    },
    setToggleMyLocation(state, action: PayloadAction<boolean>) {
      state.toggleMyLocation = action.payload;
    },
    setCurrentShownProjects(
      state,
      action: PayloadAction<{ f: IMapPin[]; h: IMapPin[] }>
    ) {
      state.listLoadingData = true;
      state.currentShownHeroes = action.payload.h;
      state.currentShownFriends = action.payload.f;
    },
    setCurrentShownHeroes(
      state,
      action: PayloadAction<{ f: IMapPin[]; h: IMapPin[] }>
    ) {
      state.currentShownHeroes = action.payload.h;
    },
    setCurrentShownFriends(state, action: PayloadAction<IMapPin[]>) {
      state.currentShownFriends = action.payload;
    },
    setProjectDetailsProject(state, action: PayloadAction<IMapPin | null>) {
      state.projectDetailsProject = action.payload;
    },
    toggleFilterSettings(state, action: PayloadAction<boolean>) {
      state.showFilterSettings = action.payload;
    },
    loadDataToTheList(state, action: PayloadAction<boolean>) {
      state.listLoadingData = action.payload;
    },
    toggleProjectList(state, action: PayloadAction<boolean>) {
      state.showProjectList = action.payload;
    },
    updateInitState(
      state,
      action: PayloadAction<[number, number, number, number]>
    ) {
      state.mainFilter = {
        capacity: {
          min: action.payload[0],
          max: action.payload[1],
          initMin: action.payload[0],
          initMax: action.payload[1],
        },
        storage: {
          min: action.payload[2],
          max: action.payload[3],
          initMin: action.payload[2],
          initMax: action.payload[3],
        },
        hasChanges: false,
      };
    },
    setCapacity(state, action: PayloadAction<[number, number]>) {
      state.mainFilter = {
        ...state.mainFilter,
        capacity: {
          initMin: state.mainFilter.capacity.initMin,
          initMax: state.mainFilter.capacity.initMax,
          min: action.payload[0],
          max: action.payload[1],
        },
        hasChanges: true,
      };
    },
    setStorage(state, action: PayloadAction<[number, number]>) {
      state.mainFilter = {
        ...state.mainFilter,
        storage: {
          initMin: state.mainFilter.storage.initMin,
          initMax: state.mainFilter.storage.initMax,
          min: action.payload[0],
          max: action.payload[1],
        },
        hasChanges: true,
      };
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(getMapData.fulfilled, (state: any, action: any) => {
      let newState = {
        ...state,
        ...action.payload,
      };
      if (
        state.externalConfiguration?.type === FilterTypes.AMBASSADOR ||
        state.externalConfiguration?.hideProjectPins
      ) {
        newState.filteredFamilyList = [];
      } else {
        newState.filteredFamilyList = newState.family;
      }
      if (!state.externalConfiguration?.hideHeroesPins)
        newState.filteredAmbassadorList = newState.ambassador;
      newState.loadingState = false;
      return newState;
    });
  },
});

export default mapDataSlicer.reducer;
