import { useEffect, useRef, useState } from "react";

const IntersectionHook = ({ root = null, threshold = 0 }) => {
  let entryValue: any = {};
  const [entry, updateEntry] = useState(entryValue);
  const [node, setNode] = useState(null);

  const observer = useRef(
    new window.IntersectionObserver(([entry]) => updateEntry(entry), {
      root,
      threshold,
    })
  );

  useEffect(() => {
    const { current: currentObserver } = observer;
    currentObserver.disconnect();

    if (node) {
      // @ts-ignore
      currentObserver.observe(node);
    }

    return () => currentObserver.disconnect();
  }, [node]);

  return [setNode, entry];
};
export default IntersectionHook;
