import { FieldRenderProps } from "react-final-form";
import FormError from "../FormError/FormError";
import "./myCheckbox.scss";

const MyCheckbox = (props: FieldRenderProps<any>) => {
  const {
    id,
    label,
    input,
    meta: { touched, error },
  } = props;

  return (
    <div className="my-checkbox">
      <label>
        <input {...input} type="checkbox" id={id} />
        <span>{label}</span>
      </label>
      <FormError message={error} visible={touched && error} />
    </div>
  );
};

export default MyCheckbox;
