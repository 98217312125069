import "./projectContainer.scss";
import MobileProjects from "../mobileProjects/MobileProjects";
import showHideIcon from "../../../../assets/chevron.svg";
import { connect } from "react-redux";
import { IProperties } from "../../../../interfaces/iProperties";
import { mapDataSlicer } from "../../../../store/reducers/mapReducer";
import { IProjectContainerProps } from "../../../../interfaces/iProjectContainerProps";
import T from "i18n-react";

function mapStateToProps(state: IProperties) {
  return {
    showProjectList: state.mapReducer?.showProjectList,
  };
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    toggleProjectList: (value: boolean) =>
      dispatch(mapDataSlicer.actions.toggleProjectList(value)),
  };
};

const ProjectContainer = (props: IProjectContainerProps) => {
  const { showProjectList } = props;
  return (
    <div className={"mobile-projects-container"}>
      <MobileProjects map={props.map} />
      <button
        className={"project-list-toggle" + (showProjectList ? " active" : "")}
        onClick={() => props.toggleProjectList(!showProjectList)}
      >
        <img alt={"toggle results"} src={showHideIcon} />
        <div className="text">
          {showProjectList
            ? T.translate("hideProjects")
            : T.translate("showProjects")}
        </div>
      </button>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectContainer);
