import { FieldRenderProps } from "react-final-form";
import FormError from "../FormError/FormError";
import "./myInput.scss";

const MyInput = (props: FieldRenderProps<any>) => {
  const {
    name,
    label,
    placeholder,
    input,
    required,
    meta: { active, touched, error },
  } = props;

  return (
    <div className="my-input">
      <label className="main-label">{label + (required ? "*" : "")}</label>
      <input
        className="input"
        {...input}
        placeholder={active ? "" : placeholder}
        type="text"
        aria-label={name}
      />
      <FormError message={error} visible={touched && error} />
    </div>
  );
};

export default MyInput;
