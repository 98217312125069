import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { mapDataSlicer } from "../../store/reducers/mapReducer";
import { IMapPin } from "../../interfaces/iMapPin";
import { IProperties } from "../../interfaces/iProperties";
import "./clientTypeFilter.scss";
import { FilterTypes } from "../../helpers/enums/filterTypes";
import T from "i18n-react";
import usersSvg from "../../assets/users.svg";
import usersActiveSvg from "../../assets/users-active.svg";
import shieldSvg from "../../assets/shield-alt.svg";
import shieldActiveSvg from "../../assets/shield-alt-active.svg";

const mapStoreToProps = (state: IProperties) => state;

const mapDispatchToProps = (dispatch: any) => {
  return {
    filterFamily: (list: IMapPin[]) =>
      dispatch(mapDataSlicer.actions.filterFamily(list)),
    setClientFilter: (filter: string) =>
      dispatch(mapDataSlicer.actions.setClientFilter(filter)),
  };
};

type FilterActions = {
  filterFamily: Function;
  setClientFilter: Function;
};

const ClientTypeFilter = (props: IProperties & FilterActions) => {
  const filterFamily = () => {
    props.setClientFilter(FilterTypes.FAMILY);
  };

  const filterAmbassador = () => {
    props.setClientFilter(FilterTypes.AMBASSADOR);
  };

  let prevCurrentShownHeroesListRef = useRef(
    props.mapReducer?.externalConfiguration?.type
  );
  useEffect(() => {
    let cType = props.mapReducer?.externalConfiguration?.type;
    if (
      prevCurrentShownHeroesListRef.current !== cType &&
      cType !== undefined
    ) {
      prevCurrentShownHeroesListRef.current = cType;
      props.setClientFilter(FilterTypes.AMBASSADOR);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.mapReducer?.currentShownHeroes]);

  const clientFilter = props.mapReducer?.clientFilter;
  const isFamilyActive = clientFilter === FilterTypes.FAMILY;
  const isAmbassadorActive = clientFilter === FilterTypes.AMBASSADOR;

  return (
    <div className={"client-type-filter"}>
      <div
        className={
          "checked-filter" + (isAmbassadorActive ? " ambassador" : " family")
        }
      />
      <button
        className={isFamilyActive ? " active" : ""}
        onClick={filterFamily}
      >
        <div className="filter-image">
          <img
            src={usersActiveSvg}
            className={"family-image" + (isFamilyActive ? " active" : "")}
            alt="heart"
          />
          <img
            src={usersSvg}
            className={"family-image" + (!isFamilyActive ? " active" : "")}
            alt="heart"
          />
        </div>
        <div className="filter-name">zociety</div>
      </button>
      <button
        onClick={filterAmbassador}
        className={isAmbassadorActive ? " active" : ""}
      >
        <div className="filter-image">
          <img
            src={shieldActiveSvg}
            className={"hero-image" + (isAmbassadorActive ? " active" : "")}
            alt="shield"
          />
          <img
            src={shieldSvg}
            className={"hero-image" + (!isAmbassadorActive ? " active" : "")}
            alt="shield"
          />
        </div>
        <div className="filter-name">{T.translate("zolarAmbassador")}</div>
      </button>
    </div>
  );
};

export default connect(mapStoreToProps, mapDispatchToProps)(ClientTypeFilter);
