import { IMapPin } from "../interfaces/iMapPin";
import IGooglePoint from "../interfaces/google/iGooglePoint";
import { pointType } from "./enums/pointType";
import friendMarker from "../assets/marker-friend.svg";
import heroMarker from "../assets/marker-hero.svg";
import partnerMarker from "../assets/marker-partner.svg";
import { Marker } from "../components/map/Marker";
import { IPosition } from "../interfaces/iPosition";
import { Bounds } from "google-map-react";
import { IClusterSize } from "../interfaces/iClusterSize";
import { isMobile } from "./deviceHelper";
import { IMainFilter } from "../interfaces/iMainFilter";

export const panMap = (mapReference: any) =>
  isMobile()
    ? mapReference.current.panBy(0, 130)
    : mapReference.current.panBy(-225, 0);

/**
 * Get an array of GeoJSON Points for clustering
 * @param pins
 * @param type
 */
export const getPoints = (pins: IMapPin[], type?: string): IGooglePoint[] =>
  pins.map((point) => ({
    type: "Feature",
    properties: {
      cluster: false,
      pointId: point.id,
      category: type ?? "other",
      point: point,
    },
    geometry: {
      type: "Point",
      coordinates: [point.position.lng, point.position.lat],
    },
  }));

export const filterPointsByValues = (
  pins: IMapPin[],
  filterState: IMainFilter
) => {
  return pins?.filter(
    (i) =>
      i.storageCapacity <= filterState.storage.max &&
      i.storageCapacity >= filterState.storage.min &&
      i.systemCapacity >= filterState.capacity.min &&
      i.systemCapacity <= filterState.capacity.max
  );
};

/**
 * Retrieve cluster size based on points count
 * @param pointsCount :number
 */
const getClusterSize = (pointsCount: number): number | undefined =>
  clusterSizes.find((el) => el.min <= pointsCount && el.max >= pointsCount)
    ?.clusterSize;

/**
 * Cluster sizing by points count
 */
const clusterSizes: IClusterSize[] = [
  {
    min: 0,
    max: 10,
    clusterSize: 1,
  },
  {
    min: 11,
    max: 100,
    clusterSize: 15,
  },
  {
    min: 101,
    max: 500,
    clusterSize: 30,
  },
  {
    min: 501,
    max: 9999999,
    clusterSize: 60,
  },
];

/**
 * Get specific markers by cluster type
 * @param clusters
 * @param currentZoomLevel
 * @param type
 * @param selectedPin
 * @param setSelectedPin
 * @param fitToCluster
 * @param mapRef
 * @param superCluster
 */
export const getMarkers = (
  clusters: any,
  currentZoomLevel: number,
  type: string,
  selectedPin: IMapPin | null,
  setSelectedPin: Function,
  fitToCluster: Function,
  mapRef: any,
  superCluster: any
) => {
  let icon: any;
  switch (type) {
    case pointType.FRIEND:
      icon = friendMarker;
      break;
    case pointType.HERO:
      icon = heroMarker;
      break;
    case pointType.PARTNER:
      icon = partnerMarker;
      break;
  }

  const togglePin = (point: IMapPin | null) => {
    if (point === selectedPin) {
      setSelectedPin(null);
    } else {
      if (mapRef) {
        const bounds = new window.google.maps.LatLngBounds();
        bounds.extend(
          new window.google.maps.LatLng(
            point?.position.lat,
            point?.position.lng
          )
        );
        mapRef.current.fitBounds(bounds);
        panMap(mapRef);
      }
      setSelectedPin(point);
    }
  };

  return clusters.map((cluster: any) => {
    const [longitude, latitude] = cluster.geometry.coordinates;
    const {
      cluster: isCluster,
      point_count: pointCount,
      point,
    } = cluster.properties;
    if (isCluster) {
      const clusterSize = getClusterSize(pointCount);
      return (
        <Marker key={`cluster-${cluster.id}`} lat={latitude} lng={longitude}>
          <div
            className={`cluster-marker ${type}`}
            style={{
              width: `${clusterSize}px`,
              height: `${clusterSize}px`,
            }}
            onClick={() => {
              fitToCluster(superCluster, cluster.id);
            }}
          >
            {pointCount}
          </div>
        </Marker>
      );
    }
    return (
      <Marker key={`point-${Math.random()}`} lat={latitude} lng={longitude}>
        <div
          className={`marker ${type} ${
            selectedPin && selectedPin.id === point.id ? "selected" : ""
          }`}
        >
          <img
            className="marker-image"
            width={currentZoomLevel * 3}
            src={icon}
            alt={type}
            onClick={
              point.type !== pointType.PARTNER
                ? () => togglePin(point)
                : undefined
            }
          />
          {/*{selectedPin &&*/}
          {/*selectedPin.id === point.id &&*/}
          {/*selectedPin.type === point.type ? (*/}
          {/*  <Tooltip togglePinCallback={togglePin} project={selectedPin} />*/}
          {/*) : null}*/}
        </div>
      </Marker>
    );
  });
};

export const rad = (x: any) => (x * Math.PI) / 180;

export const findClosestMarker = (
  markers: IMapPin[],
  currentLocation: IPosition
): Bounds => {
  let lat = currentLocation.lat;
  let lng = currentLocation.lng;
  let R = 6371; // radius of earth in km
  let distances = [];
  let closest = -1;
  for (let i = 0; i < markers.length; i++) {
    let mLat = markers[i].position.lat;
    let mLng = markers[i].position.lng;
    let dLat = rad(mLat - lat);
    let dLong = rad(mLng - lng);
    let a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(rad(lat)) *
        Math.cos(rad(lat)) *
        Math.sin(dLong / 2) *
        Math.sin(dLong / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let d = R * c;
    distances[i] = d;
    if (closest === -1 || d < distances[closest]) {
      closest = i;
    }
  }
  let closestMarker = markers[closest].position;
  // @ts-ignore
  let bounds = new window.google.maps.LatLngBounds();
  let newLocation: IPosition = {
    lat: currentLocation.lat - (closestMarker.lat - currentLocation.lat),
    lng: currentLocation.lng - (closestMarker.lng - currentLocation.lng),
  };
  bounds.extend(newLocation);
  bounds.extend(closestMarker);
  return bounds;
};

export default getMarkers;
