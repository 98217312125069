/**
 * @param dateString - Date to format (for example: "2021-04-30")
 * @param locale - Location parameter for proper month translation (for example: "de")
 * @return Date in format [Month name] [Year] (for example: "April 2021")
 */
export const getMonthYearDateFormatted = (
  dateString: string,
  locale: string
) => {
  const dateObject = new Date(dateString);
  if (!dateObject) return dateString;
  const month = dateObject.toLocaleString(locale, { month: "long" });
  const year = dateObject.getFullYear();
  return month + " " + year;
};
