import sliders from "../../../assets/sliders-h.svg";
import close from "../../../assets/close-black.svg";
import "./mobileFilter.scss";
import MainFilter from "../../mainFilter/MainFilter";
import { IGoogleMapReference } from "../../../interfaces/google/iGoogleMapReference";
import { IProperties } from "../../../interfaces/iProperties";
import { mapDataSlicer } from "../../../store/reducers/mapReducer";
import { connect } from "react-redux";
import Legend from "../../legend/Legend";
import React from "react";
import ClientTypeFilter from "../../clientTypeFilter/ClientTypeFilter";

const mapStoreToProps = (state: IProperties) => {
  return {
    showFilterSettings: state.mapReducer?.showFilterSettings,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    toggleFilterSettings: (toggle: boolean) =>
      dispatch(mapDataSlicer.actions.toggleFilterSettings(toggle)),
  };
};

type MobileFilterOptions = {
  toggleFilterSettings: Function;
  showFilterSettings: boolean | undefined;
};

const MobileFilter = (props: IGoogleMapReference & MobileFilterOptions) => {
  return (
    <div className={"mobile-filter"}>
      <div
        className="location-button"
        onClick={() => props.toggleFilterSettings(!props.showFilterSettings)}
      >
        {props.showFilterSettings && <img alt={""} src={close} />}
        {!props.showFilterSettings && <img alt={""} src={sliders} />}
      </div>
      <div className="filter-switcher">
        <ClientTypeFilter />
      </div>
      <div
        className={
          "mobile-main-filter" + (props.showFilterSettings ? " show" : "")
        }
      >
        <Legend />
        <MainFilter map={props.map} />
      </div>
    </div>
  );
};

export default connect(mapStoreToProps, mapDispatchToProps)(MobileFilter);
