import { Component } from "react";
import { connect } from "react-redux";
import { IProperties } from "../../../interfaces/iProperties";
import "./fullscreenControl.scss";
import fullscreenImage from "../../../assets/fullscreen.svg";
import fullscreenCloseImage from "../../../assets/fullscreen-off.svg";

const mapStoreToProps = (state: IProperties) => state;

type ComponentState = {
  currentlyFullscreen: boolean;
};

class FullscreenControl extends Component<IProperties> {
  state: ComponentState = {
    currentlyFullscreen: false,
  };

  toggleFullscreen = () => {
    let containerId =
      this.props?.mapReducer?.externalConfiguration?.mountPoint ??
      "zolarMapMountContainer";
    let container = document.getElementById(containerId);
    if (container) {
      this.setState({ currentlyFullscreen: !this.state.currentlyFullscreen });
      container.classList.toggle("fullscreen");
    }
  };

  render() {
    if (this.props?.mapReducer?.externalConfiguration?.external) {
      let imageSrc = this.state.currentlyFullscreen
        ? fullscreenCloseImage
        : fullscreenImage;
      return (
        <div className={"fullscreen-activator"} onClick={this.toggleFullscreen}>
          <img alt={""} src={imageSrc} />
        </div>
      );
    }
    return <></>;
  }
}

export default connect(mapStoreToProps)(FullscreenControl);
