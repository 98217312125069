import "./popup.scss";
import React from "react";
import { connect } from "react-redux";
import usersSvg from "../../../assets/users-black.svg";
import popupImage from "../../../assets/popup-image.jpg";
import Legend from "../../legend/Legend";
import T from "i18n-react";
import { IProperties } from "../../../interfaces/iProperties";

type PopupProps = {
  isPopupOpen: boolean;
};

const mapStateToProps = (state: IProperties) => {
  return {
    isPopupOpen: state.mapReducer?.isPopupOpen ?? false,
  };
};

const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  window.localStorage.setItem("hide-popup", e.target.checked.toString());
};

const Popup = (props: PopupProps) => {
  const isPopupOpen = props.isPopupOpen;

  if (!isPopupOpen) {
    return null;
  }

  return (
    <div className="popup">
      <div
        className="top-image"
        style={{
          backgroundImage: `url(${popupImage})`,
        }}
      />
      <div className="content-container">
        <div className="main-content">
          <div className="title-container">
            <img src={usersSvg} alt="Group" />
            <div className="title-text">{T.translate("popupTitle")}</div>
          </div>
          <div className="details">
            <p>{T.translate("popupContentFirstParagraph")}</p>
            <p>{T.translate("popupContentSecondParagraph")}</p>
          </div>
        </div>
        <Legend />
        <div className="dont-show-again">
          <input
            type="checkbox"
            id="dont-show-again"
            name="dont-show-again"
            onChange={(e) => handleCheckboxChange(e)}
          />
          <label htmlFor="dont-show-again">
            {T.translate("dontShowPopupCheckbox")}
          </label>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(Popup);
