import { createAsyncThunk } from "@reduxjs/toolkit";
import { GET_MAP_DATA } from "../consts";
import envParameters from "../../helpers/envParameters.config";
import { pointType } from "../../helpers/enums/pointType";

export const updateMapData = () =>
  fetch(envParameters.REACT_APP_API_URL)
    .then((response) => response.json())
    .then((response) => {
      let family = response.family;
      let ambassador = response.ambassador;
      let partner = response.installation_partner;
      family = family
        .map((family: any) => {
          if (!family.systemPicture?.startsWith("https://")) {
            family.systemPicture = "";
          }
          return family;
        })
        .map((family: any, index: number) => ({
          ...family,
          id: index,
          type: pointType.FRIEND,
        }));
      ambassador = ambassador
        .map((ambassador: any) => {
          if (!ambassador.systemPicture?.startsWith("https://")) {
            ambassador.systemPicture = "";
          }
          return ambassador;
        })
        .map((ambassador: any) => ({
          ...ambassador,
          type: pointType.HERO,
        }));
      partner = partner.map((partner: any) => ({
        ...partner,
        type: pointType.PARTNER,
      }));
      return {
        family,
        ambassador,
        partner,
      };
    });

export const getMapData = createAsyncThunk(GET_MAP_DATA, async () => {
  return await updateMapData();
});
