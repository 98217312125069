import "./globalLoader.scss";
import ZAnimation from "./zAnimation/ZAnimation";

type Loading = {
  loading?: boolean;
};
const LocalLoader = (props: Loading) => {
  return (
    <>
      {!props.loading && <></>}
      {props.loading && (
        <div className={"global-loader"}>
          <div className={"zolar-loader"}>
            <ZAnimation />
          </div>
        </div>
      )}
    </>
  );
};

export default LocalLoader;
