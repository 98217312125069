import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { FieldRenderProps } from "react-final-form";
import { EnvParametersList } from "../../../helpers/enums/envParametersList";
import envParameters from "../../../helpers/envParameters.config";

const REACT_APP_GOOGLE_RECAPTCHA_KEY =
  envParameters[EnvParametersList.REACT_APP_GOOGLE_RECAPTCHA_KEY];

const MyRecaptcha = (props: FieldRenderProps<any>) => {
  const { recaptchaRef } = props;

  return (
    <div>
      {/* @ts-ignore */}
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={REACT_APP_GOOGLE_RECAPTCHA_KEY}
      />
    </div>
  );
};

export default MyRecaptcha;
