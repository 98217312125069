import "./contactForm.scss";
import { IProperties } from "../../../../interfaces/iProperties";
import { connect } from "react-redux";
import { Form, Field } from "react-final-form";
import MyInput from "../../../formElements/MyInput/MyInput";
import {
  isRequired,
  isEmail,
  isPhoneNumber,
  isAccepted,
  composeValidators,
} from "../../../../helpers/formValidation";
import MyCheckbox from "../../../formElements/MyCheckbox/MyCheckbox";
import { IMapPin } from "../../../../interfaces/iMapPin";
import { sendPostRequest } from "../../../../helpers/fetchHelper";
import { useEffect, useRef, useState } from "react";
import MyRecaptcha from "../../../formElements/MyRecaptcha/MyRecaptcha";
import ReCAPTCHA from "react-google-recaptcha";
import T from "i18n-react";
import envParameters from "../../../../helpers/envParameters.config";
import { TimeoutsHelper } from "../../../../helpers/timeoutsHelper";

type ContactFormProps = {
  projectDetailsProject: IMapPin | null;
};

function mapStateToProps(state: IProperties) {
  return {
    projectDetailsProject: state.mapReducer?.projectDetailsProject ?? null,
  };
}

export const ContactForm = (props: ContactFormProps) => {
  const [formSendResponse, setFormSendResponse] = useState<boolean | null>(
    null
  );
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const checkboxLabel = (
    <T.text
      text={{
        key: "acceptTermsAndConditions",
        link: (
          <T.a
            href="https://www.zolar.de/datenschutz"
            target="_blank"
            text="acceptTermsAndConditionsLink"
          />
        ),
      }}
    />
  );

  // Reset "success" in case changing project selection after sending a form
  useEffect(() => {
    setFormSendResponse(null);
  }, [props, props.projectDetailsProject]);

  if (!props.projectDetailsProject) {
    return null;
  }

  const onSubmit = async (values: any) => {
    // Recaptcha check
    const recaptchaResult = await recaptchaRef.current
      ?.executeAsync()
      .catch((error) => console.error(error));
    // Recaptcha has to be reset so we avoid "Uncaught (in promise) Timeout" error after MyRecaptcha component unmount
    recaptchaRef.current?.reset();

    if (!recaptchaResult) {
      setFormSendResponse(false);
      setTimeout(
        () => setFormSendResponse(null),
        TimeoutsHelper.CONTACT_FORM_TOGGLE_SEND_STATUS
      );
      return false;
    }

    const heroId = props.projectDetailsProject?.id;
    const data = { ...values };
    const formattedRequestData = {
      prospective_customer: {
        ...data,
        salesmanager: null,
      },
    };

    const url = `${envParameters.REACT_APP_API_URL}heroes/${heroId}/contact`;
    const result = await sendPostRequest(url, formattedRequestData);

    if (result && result.success) {
      setFormSendResponse(true);
    } else {
      setFormSendResponse(false);
      setTimeout(
        () => setFormSendResponse(null),
        TimeoutsHelper.CONTACT_FORM_TOGGLE_SEND_STATUS
      );
    }
  };

  if (formSendResponse !== null) {
    return (
      <div className="form-container message">
        <div className="title">
          {formSendResponse
            ? T.translate("formSendSuccess")
            : T.translate("formSendFailure")}
        </div>
        <div className="content">
          {formSendResponse
            ? T.translate("formSendSuccessDetails")
            : T.translate("formSendFailureDetails")}
        </div>
      </div>
    );
  }

  return (
    <div className="form-container">
      <div className="form-title">{T.translate("formTitle")}</div>
      <Form onSubmit={onSubmit} initialValues={{ salutation: null }}>
        {({ handleSubmit }) => (
          <form id={"contactForm"} className="form" onSubmit={handleSubmit}>
            {/*<MyRadioGroup*/}
            {/*  name="salutation"*/}
            {/*  options={salutationOptions}*/}
            {/*  label={T.translate("salutation")?.toString() || "salutation"}*/}
            {/*  validate={isRequired}*/}
            {/*/>*/}
            <div className={"names"}>
              <Field
                name="firstName"
                component={MyInput}
                label={
                  T.translate("firstNameLabel")?.toString() || "firstNameLabel"
                }
                placeholder={
                  T.translate("firstNamePlaceholder")?.toString() ||
                  "firstNamePlaceholder"
                }
                validate={isRequired}
                required
              />
              <Field
                name="lastName"
                component={MyInput}
                label={
                  T.translate("lastNameLabel")?.toString() || "lastNameLabel"
                }
                placeholder={
                  T.translate("lastNamePlaceholder")?.toString() ||
                  "lastNamePlaceholder"
                }
                validate={isRequired}
                required
              />
            </div>
            <Field
              name="phone"
              component={MyInput}
              label={T.translate("phoneLabel")?.toString() || "phoneLabel"}
              placeholder={
                T.translate("phonePlaceholder")?.toString() ||
                "phonePlaceholder"
              }
              validate={composeValidators(isRequired, isPhoneNumber)}
              required
            />
            <Field
              name="email"
              component={MyInput}
              label={T.translate("emailLabel")?.toString() || "emailLabel"}
              placeholder={
                T.translate("emailPlaceholder")?.toString() ||
                "emailPlaceholder"
              }
              validate={composeValidators(isRequired, isEmail)}
              required
            />
            <Field
              name="acceptTermsAndConditions"
              component={MyCheckbox}
              label={checkboxLabel}
              validate={isAccepted}
              type="checkbox"
            />
            <Field
              name="recaptcha"
              component={MyRecaptcha}
              recaptchaRef={recaptchaRef}
            />
            <button
              className="button-primary button-black button-small"
              type="submit"
            >
              {T.translate("requestRecall")}
            </button>
          </form>
        )}
      </Form>
    </div>
  );
};

export default connect(mapStateToProps)(ContactForm);
