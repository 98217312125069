import thunkMiddleware from "redux-thunk";
import mapReducer from "./reducers/mapReducer";
import {configureStore, MiddlewareArray,} from "@reduxjs/toolkit";
import {IState} from "../interfaces/iState";

let preloadState: any;
const store = configureStore({
    reducer: {mapReducer: mapReducer},
    preloadedState: preloadState,
    middleware: new MiddlewareArray().concat(thunkMiddleware),
});
export const initStore = (preload?: IState | null) => {
    preloadState = preload;
    return store;
};
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
