import "./legend.scss";
import markerHero from "../../assets/marker-hero.svg";
import markerFriend from "../../assets/marker-friend.svg";
import { connect } from "react-redux";
import markerPartner from "../../assets/marker-partner.svg";
import T from "i18n-react";
import { IProperties } from "../../interfaces/iProperties";

type hidePins = {
  hideProjects: boolean;
  hideHeroes: boolean;
  hidePartners: boolean;
};

const mapStoreToProps = (state: IProperties) => {
  return {
    hideProjects:
      state.mapReducer?.externalConfiguration?.hideProjectPins ?? false,
    hideHeroes:
      state.mapReducer?.externalConfiguration?.hideHeroesPins ?? false,
    hidePartners:
      state.mapReducer?.externalConfiguration?.hidePartnerPins ?? false,
  };
};

const Legend = (props: hidePins) => {
  return (
    <div className="legend">
      {!props.hideProjects && (
        <div className="element">
          <img className="image" src={markerFriend} alt="Zociety marker" />
          <div className="content">
            <div className="header">{T.translate("zolarProject")}</div>
            <div className="description">
              {T.translate("zocietyLegendDescription")}
            </div>
          </div>
        </div>
      )}
      {!props.hideHeroes && (
        <div className="element">
          <img className="image" src={markerHero} alt="Hero marker" />
          <div className="content">
            <div className="header">{T.translate("zolarAmbassador")}</div>
            <div className="description">
              {T.translate("heroLegendDescription")}
            </div>
          </div>
        </div>
      )}
      {!props.hidePartners && (
        <div className="element">
          <img className="image" src={markerPartner} alt="Partner marker" />
          <div className="content">
            <div className="header">
              {T.translate("zolarInstallationPartner")}
            </div>
            <div className="description">
              {T.translate("installationPartnerLegendDescription")}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default connect(mapStoreToProps)(Legend);
