import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { initStore } from "./store/store";
import { Provider } from "react-redux";
import { IExternalConfiguration } from "./interfaces/iExternalConfiguration";
import { IEnvParameters } from "./interfaces/iEnvParameters";
import T from "i18n-react";
import { initialState } from "./store/initStates/initMainState";
import { parse } from "./helpers/externalIframeParser";

import "@zolargmbh/design-system/tailwind.css";
import "./index.scss";

declare global {
  interface Window {
    ZOLARMAP: any;
    google: any;
    globalConfig: IEnvParameters;
  }
}

let _args: IExternalConfiguration = {};
if (window && window.location && URLSearchParams) {
  let searchParameters = new URLSearchParams(window.location.search);
  _args = parse(searchParameters);
}
const store = initStore();

T.setTexts(require("./translations/de_De.json"));

let mountElement = document.getElementById("mapRootPoint");
if (mountElement) {
  ReactDOM.render(
    <React.StrictMode>
      {/* @ts-ignore */}
      <Provider store={store}>
        <App mapReducer={initialState} externalConfiguration={_args} />
      </Provider>
    </React.StrictMode>,
    mountElement
  );
} else {
  window.ZOLARMAP = (function () {
    return {
      init: function (args: IExternalConfiguration) {
        _args = args;
        this._start();
      },
      _start: function () {
        let mountId = _args.mountPoint ?? "zolarMapMountContainer";
        if (!_args.external) {
          _args.external = true;
        }
        let mountElement = document.getElementById(mountId);
        if (mountElement) {
          ReactDOM.render(
            <React.StrictMode>
              {/* @ts-ignore */}
              <Provider store={store}>
                <App mapReducer={initialState} externalConfiguration={_args} />
              </Provider>
            </React.StrictMode>,
            mountElement
          );
        }
      },
    };
  })();
}
