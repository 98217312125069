import { IMainFilter } from "../../interfaces/iMainFilter";

export const initFilterState: IMainFilter = {
  capacity: {
    min: 0,
    max: 0,
    initMin: 0,
    initMax: 0,
  },
  storage: {
    min: 0,
    max: 0,
    initMin: 0,
    initMax: 0,
  },
  hasChanges: false,
};
