import IGoogleConfig from "../interfaces/google/iGoogleConfig";
import IGoogleMapClusterOptions from "../interfaces/google/iGoogleMapClusterOptions";
import { defaultMapStyles } from "./googleMapStyle.config";
import envParameters from "./envParameters.config";
import { getInitZoom, getInitCoordinates } from "./deviceHelper";

export const maxZoom = 12;

export const mapConfig: IGoogleConfig = {
  bootstrapURLKeys: {
    key: envParameters.REACT_APP_GOOGLE_MAP_KEY,
    libraries: "places",
  },
  defaultCenter: getInitCoordinates(),
  defaultZoom: getInitZoom(),
  options: {
    minZoom: 6,
    maxZoom: maxZoom,
    mapTypeControl: false,
    streetViewControl: false,
    fullscreenControl: false,
    zoomControl: true,
    clickableIcons: false,
    styles: defaultMapStyles,
  },
};

export const clusterConfig: IGoogleMapClusterOptions = {
  radius: 90,
  maxZoom: maxZoom - 1,
};
