import React, { useEffect } from "react";
import "./App.scss";
import { IProperties } from "./interfaces/iProperties";
import { mapDataSlicer } from "./store/reducers/mapReducer";
import { connect } from "react-redux";
import FullscreenControl from "./components/map/controls/FullscreenControl";
import { IExternalConfiguration } from "./interfaces/iExternalConfiguration";
import GlobalLoader from "./components/globalLoader/GlobalLoader";
import MapWrapper from "./components/map/Map";
import Modal from "./components/modal/Modal";
import Lightbox from "./components/modal/lightbox/Lightbox";
import { getMapData } from "./store/actions/mapActions";
import Legend from "./components/legend/Legend";
import { isMobile } from "./helpers/deviceHelper";
import Popup from "./components/modal/popup/Popup";

const mapDispatchToProps = (dispatch: any) => {
  return {
    setExternalConfiguration: (value: IExternalConfiguration) =>
      dispatch(mapDataSlicer.actions.setExternal(value)),
    updateMapData: () => dispatch(getMapData()),
    openPopup: () => dispatch(mapDataSlicer.actions.openPopup()),
  };
};

const App = (props: IProperties) => {
  const hidePopup = window.localStorage.getItem("hide-popup") === "true";
  const openPopup = props.openPopup;
  useEffect(() => {
    if (!hidePopup && !props.externalConfiguration?.hideIntroModal) {
      openPopup();
    }
  }, [hidePopup, openPopup, props.externalConfiguration?.hideIntroModal]);

  useEffect(() => {
    if (props.externalConfiguration && props.setExternalConfiguration) {
      props.setExternalConfiguration(props.externalConfiguration);
    }
    if (props?.updateMapData) props?.updateMapData();
  });

  return (
    <div>
      <FullscreenControl />
      <GlobalLoader />
      <MapWrapper />
      {!isMobile() && !props.externalConfiguration?.hideLegend ? (
        <Legend />
      ) : null}
      <Modal>
        <Popup />
        <Lightbox />
      </Modal>
    </div>
  );
};

export default connect(null, mapDispatchToProps)(App);
