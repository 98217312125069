export const sendPostRequest = (url: string, data: Object) => {
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((response) => response)
    .catch((error) => console.error("FETCH ERROR", error));
  // TODO: Handle error with Sentry
};
