import { IMainFilter } from "../interfaces/iMainFilter";

export const isAnyFilterSet = (mainFilter: IMainFilter): boolean => {
  const capacity = mainFilter.capacity;
  const storage = mainFilter.storage;
  return (
    capacity.initMin !== capacity.min ||
    capacity.initMax !== capacity.max ||
    storage.initMin !== storage.min ||
    storage.initMax !== storage.max
  );
};
