import { IMapPin } from "../interfaces/iMapPin";
import { pointType } from "./enums/pointType";

export const getProjectKey = (project: IMapPin): string => {
  switch (project.type) {
    case pointType.FRIEND:
      return "fm" + project.id;
    case pointType.HERO:
      return "hm" + project.id;
    case pointType.PARTNER:
      return "pm" + project.id;
  }
  return project.id.toString();
};
